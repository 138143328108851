import { store } from '@/store/store';
import { cab_valor_campos_personalizado } from '@/shared/dtos/cab_valor_campos_personalizado';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { UtilsFiles } from '@/utils/utils-files';

@Module({
    namespaced: true,
    name: 'cab_valor_campos_personalizadoModule',
    store,
    dynamic: true
})
class cab_valor_campos_personalizadoModule extends VuexModule {
    public cab_valor_campos_personalizados: cab_valor_campos_personalizado[] = [];
    public cab_valor_campos_personalizado: cab_valor_campos_personalizado = new cab_valor_campos_personalizado();

    @Action({ commit: 'onGetcab_valor_campos_personalizados' })
    public async getcab_valor_campos_personalizados() {
        return await ssmHttpService.get(API.cab_valor_campos_personalizado);
    }
    @Action({ commit: 'onGetcab_valor_campos_personalizados' })
    public async getcab_valor_campos_personalizados_id_paciente({ id_paciente, id_tipo }: any) {
        return await ssmHttpService.get(API.cab_valor_campos_personalizado + '/mediciones/' + id_paciente + '/' + id_tipo);
    }
    @Action({ commit: 'onGetcab_valor_campos_personalizado' })
    public async getcab_valor_campos_personalizado(id: any) {
        return await ssmHttpService.get(API.cab_valor_campos_personalizado + '/' + id);
    }

    @Action({ commit: 'onGetdescargapdf' })
    public async getpdf_medicion(id: any) {
        return await ssmHttpService.get(API.cab_valor_campos_personalizado + '/pdf_mediciones/' + id);
    }

    @Action
    public async guardarcab_valor_campos_personalizado(cab_valor_campos_personalizado: cab_valor_campos_personalizado) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.cab_valor_campos_personalizado, cab_valor_campos_personalizado.toJson());
        this.getcab_valor_campos_personalizados();
    }

    @Action
    public async modificarcab_valor_campos_personalizado(cab_valor_campos_personalizado: cab_valor_campos_personalizado) {
        await ssmHttpService.put(API.cab_valor_campos_personalizado, cab_valor_campos_personalizado);
        this.getcab_valor_campos_personalizados();
    }

    @Action
    public async eliminarcab_valor_campos_personalizado(id: number) {
        await ssmHttpService.delete(API.cab_valor_campos_personalizado + '/' + id, null, false);
    }

    @Mutation
    public onGetcab_valor_campos_personalizados(res: cab_valor_campos_personalizado[]) {
        this.cab_valor_campos_personalizados = res ? res.map((x) => new cab_valor_campos_personalizado(x)) : [];
    }

    @Mutation
    public onGetcab_valor_campos_personalizado(res: cab_valor_campos_personalizado) {
        this.cab_valor_campos_personalizado = new cab_valor_campos_personalizado(res);
    }
    @Mutation
    public async onGetdescargapdf(response: any) {
        UtilsFiles.CreateURLFromBase64AndOpen(response, 'application/pdf');
    }

}
export default getModule(cab_valor_campos_personalizadoModule);