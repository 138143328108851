import { store } from '@/store/store';
import { id_nombre } from '@/shared/dtos/generalesDto/id_nombre';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'tipos_campos_personalizadosModule',
    store,
    dynamic: true
})
class tipos_campos_personalizadosModule extends VuexModule {
    public tipos_campos_personalizadoses: id_nombre[] = [];
    public tipos_campos_personalizados: id_nombre = new id_nombre();

    @Action({ commit: 'onGettipos_campos_personalizadoses' })
    public async gettipos_campos_personalizadoses() {
        return await ssmHttpService.get(API.tipos_campos_personalizados);
    }

    @Action({ commit: 'onGettipos_campos_personalizados' })
    public async gettipos_campos_personalizados(id: any) {
        return await ssmHttpService.get(API.tipos_campos_personalizados + '/' + id);
    }

    @Action
    public async guardartipos_campos_personalizados(tipos_campos_personalizados: id_nombre) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.tipos_campos_personalizados, tipos_campos_personalizados.toJson());
        this.gettipos_campos_personalizadoses();
    }

    @Action
    public async modificartipos_campos_personalizados(tipos_campos_personalizados: id_nombre) {
        await ssmHttpService.put(API.tipos_campos_personalizados, tipos_campos_personalizados);
        this.gettipos_campos_personalizadoses();
    }

    @Action
    public async eliminartipos_campos_personalizados(tipos_campos_personalizados: id_nombre) {
        await ssmHttpService.delete(API.tipos_campos_personalizados + '/' + tipos_campos_personalizados.id, null, false);
        this.gettipos_campos_personalizadoses();
    }

    @Mutation
    public onGettipos_campos_personalizadoses(res: id_nombre[]) {
        this.tipos_campos_personalizadoses = res ? res.map((x) => new id_nombre(x)) : [];
    }

    @Mutation
    public onGettipos_campos_personalizados(res: id_nombre) {
        this.tipos_campos_personalizados = new id_nombre(res);
    }

}
export default getModule(tipos_campos_personalizadosModule);