


































































import { Component, Vue } from "vue-property-decorator";
import tipos_campos_personalizadosModule from "@/store/modules/tipos_campos_personalizados-module.ts";
import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DatatableGenerico/datatypes";
import { UtilsString } from "@/utils/utils-string";
import { UtilsEncript } from "@/utils/utils-encript";
import cab_valor_campos_personalizadoModule from "@/store/modules/cab_valor_campos_personalizado-module";
import { RouterNames } from "@/router/routernames";
@Component({
  components: {
    DataTable: () => import("@/components/DatatableGenerico/DataTable.vue"),
    PacienteFichaOpciones: () =>
      import("@/views/pacientes/paciente-ficha-opciones.vue"),
    kineantropia_card: () =>
      import("@/views/pacientes/campos-personalizados/kineantropia-card.vue"),
  },
})
export default class PacienteCamposPersonalizados extends Vue {
  public modeltab!: number;
  public index_selected: number = 0;
  public id_paciente: number = 0;
  public show_data_mas_info = false;
  public async created() {
    await tipos_campos_personalizadosModule.gettipos_campos_personalizadoses();
    if (UtilsString.IsValidParamUrl(this.$route.query.id_paciente)) {
      this.id_paciente = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id_paciente)
      );
    }
    await cab_valor_campos_personalizadoModule.getcab_valor_campos_personalizados_id_paciente(
      { id_paciente: this.id_paciente, id_tipo: 1 }
    );
  }
  public get tabsnames() {
    return tipos_campos_personalizadosModule.tipos_campos_personalizadoses;
  }

  public get mediciones(): any[] {
    return cab_valor_campos_personalizadoModule.cab_valor_campos_personalizados;
  }
  public get Columns() {
    var Columnas: ClassColumnDataTable[] = [];
    Columnas.push(
      new ClassColumnDataTable(
        "fecha",
        "Fecha",
        datatypes.date,
        true,
        datatypes.maxlengthDate
      )
    );
    return Columnas;
  }

  add_datos_manuales(index: number) {
    this.$router.push({
      name: RouterNames.mediciones_avanzadas_entrevista,
      query: {
        id_paciente: UtilsEncript.Encriptar(this.id_paciente.toString()),
        id_tipos_campos_personalizados: UtilsEncript.Encriptar(
          (index + 1).toString()
        ),
      },
    });
  }
  public cargar_mediciones(id: number) {
    cab_valor_campos_personalizadoModule.getcab_valor_campos_personalizados_id_paciente(
      { id_paciente: this.id_paciente, id_tipo: id }
    );
  }
  public async Eliminar(rw: any) {
    await cab_valor_campos_personalizadoModule.eliminarcab_valor_campos_personalizado(
      rw
    );
  }

  public async Print(id: any) {
    await cab_valor_campos_personalizadoModule.getpdf_medicion(id);
  }
  public async Editar(id: any) {
    this.$router.push({
      name: RouterNames.mediciones_avanzadas_entrevista,
      query: {
        id_paciente: UtilsEncript.Encriptar(this.id_paciente.toString()),
        id_mediciones: UtilsEncript.Encriptar(id.toString()),
      },
    });
  }
}
